import { useEffect, useState } from 'react';
import {
  Navigate,
  Outlet,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { PageLoader } from 'src/components/templates/PageLoader/PageLoader';
import { LocalStorageKey } from 'src/constants/localStorageKeys';
import { useAppConfig } from 'src/hooks/useAppConfig/useAppConfig';
import { useSmartHireClient } from 'src/hooks/useSmartHireClient/useSmartHireClient';
import { isTokenValid } from 'src/utils/TokenValidator';

export const ProtectedRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckAuthSuccess, setIsCheckAuthSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { smartHireClient } = useSmartHireClient();
  const { environment } = useAppConfig();
  const navigate = useNavigate();

  useEffect(() => {
    const checkCredentials = async () => {
      const token = searchParams.get('token');
      try {
        await smartHireClient.checkAuth(token || undefined);
        setSearchParams('');
        setIsCheckAuthSuccess(true);
      } catch (error) {
        setIsCheckAuthSuccess(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkCredentials();
  }, [smartHireClient, searchParams, setSearchParams]);

  // automatically redirect user to login if access & refresh tokens is removed by SmartHireClient
  useEffect(() => {
    const checkRefreshToken = () => {
      if (searchParams.get('token')) {
        return;
      }

      const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
      if (!refreshToken) {
        navigate('/login');
      }
    };

    window.addEventListener('storage', checkRefreshToken);

    return () => {
      window.removeEventListener('storage', checkRefreshToken);
    };
  }, [searchParams, navigate]);

  // check access token validity whenever window is focused
  useEffect(() => {
    const checkTokenPayload = () => {
      if (document.visibilityState !== 'visible' || document.hidden) {
        return;
      }

      const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
      // let checkAuth handle the case if missing access token
      if (accessToken) {
        const isValid = isTokenValid(accessToken, environment);
        if (!isValid) {
          try {
            smartHireClient.refreshToken();
          } catch {
            // do nothing here, refreshToken method will automatically clear storage
            // and the useEffect above will automatically log user out
          }
        }
      }
    };

    document.addEventListener('visibilitychange', checkTokenPayload);
    window.addEventListener('focus', checkTokenPayload);

    return () => {
      document.removeEventListener('visibilitychange', checkTokenPayload);
      window.removeEventListener('focus', checkTokenPayload);
    };
  }, [smartHireClient, environment]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isCheckAuthSuccess) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
